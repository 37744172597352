.lobby-chat-menu {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.lobby-menu-chat {
    background-color: var(--primary-color);
    border: .13rem solid var(--background-border-color);
    border-top-color: var(--background-border-shadow-color);
    border-left-color: var(--background-border-shadow-color);
    border-radius: .5rem;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom: none;
    height: 20em;
    overflow: auto;
    flex-grow: 1;
}