.role-picker {
    display: flex;
    flex-direction: row;
    width: 100%;
}
.role-picker-option {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}
.role-outline-option-selector {
    width: 100%;
}
.role-picker select {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    border-radius: 0;
}
.role-picker select:first-of-type {
    border-top-left-radius: .4rem;
    border-bottom-left-radius: .4rem;
}
.role-picker select:last-of-type {
    border-top-right-radius: .4rem;
    border-bottom-right-radius: .4rem;
}
.role-list-setter-list {
    background-color: var(--primary-color);
    border: .13rem solid var(--primary-border-color);
    border-radius: .5rem;
    border-top-color: var(--background-border-shadow-color);
    border-left-color: var(--background-border-shadow-color);
}
.role-list-setter-list>div:nth-of-type(even) {
    background-color: var(--background-color);
}
.role-list-setter-outline-div {
    padding: 0 .25rem;
    align-items:center;
    gap: .25rem;
    display: flex;
    overflow-x: auto;
}
.role-list-setter-add-button-div {
    justify-content: center;
}
.conclusions-selector {
    position: absolute;
    min-width: max-content;
    overflow-y: auto;

    padding: .13rem .13rem;
    background-color: var(--secondary-color);
    border-radius: .25rem;
    border: .13rem solid var(--primary-border-color);
}