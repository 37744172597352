
.global-menu {
    position: fixed;
    display: flex;
    flex-direction: column;
    right: 0.38rem;
    top: 2.75rem;
    height: fit-content;
    min-width: fit-content;
    box-shadow: 0 0 .5rem #0007;

    border: solid .13rem var(--primary-border-color);
    border-bottom-color: var(--primary-border-shadow-color);
    border-right-color: var(--primary-border-shadow-color);
    border-radius: 0.5rem;

    gap: 0.5rem;
    
    padding: .5rem;
    background-color: var(--background-color);

    z-index: 100;
}

.global-menu > section {
    display: flex;
    flex-direction: column;
}

.global-menu > section.standout {
    border: solid .13rem var(--primary-border-color);
    border-top-color: var(--primary-border-shadow-color);
    border-left-color: var(--primary-border-shadow-color);
    border-radius: .5rem;
    padding: 0.25rem;
    background-color: var(--secondary-color);
}

.global-menu button {
    display: flex;
    flex-direction: row;
    gap: .25rem;
}

@media (prefers-reduced-motion = no-preference) {
    .global-menu {
        /* Start off the page and slide in */
        transform: translateX(100%);
        animation: slide-in 0.5s forwards;
        -webkit-animation: slide-in 0.5s forwards;
    }
}

@keyframes slide-in {
    100% { transform: translateX(0%); }
}