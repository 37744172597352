.settings-menu-card {
    display: flex;
    flex-direction: column;
    gap: .5rem;
    width: 100%;
}

.settings-menu-card > header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
    flex-wrap: wrap;
    gap: 0.5rem;
}

.settings-menu-card input {
    min-width: 3rem;
}

.settings-menu-card > main {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1rem;
    overflow-y: scroll;
    scrollbar-width: none;
    align-items: flex-start;
}

.settings-menu-card > main > div {
    display: flex;
    flex-direction: column;
    flex-basis: 30%;
    flex-grow: 1;
    gap: .5rem;
    max-width: 100%;
    padding: 1rem;
    background-color: var(--background-color);
    border: .13rem solid var(--primary-border-color);
    border-bottom-color: var(--primary-border-shadow-color);
    border-right-color: var(--primary-border-shadow-color);
    border-radius: 1rem;
}
.settings-menu > div > section {
    display: flex;
    flex-direction: column;
    border: solid .13rem var(--primary-border-color);
    border-top-color: var(--primary-border-shadow-color);
    border-left-color: var(--primary-border-shadow-color);
    border-radius: 0.5rem;
    padding: 0.5rem;
    background-color: var(--secondary-color);
    align-items: center;
}

.settings-menu .menu-list {
    display: flex;
    flex-direction: row;
    justify-content: center;
}