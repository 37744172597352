@font-face {
    font-family: computer-font;
    src: url('resources/fonts/3270/3270-Regular.ttf');
    font-weight: normal;
    font-style: normal;
    size-adjust: 120%;
}

@font-face {
    font-family: legible-computer-font;
    src: url('resources/fonts/Consolas/Consolas-Regular.ttf');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: legible-computer-font;
    src: url('resources/fonts/Consolas/Consolas-Bold.ttf');
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-family: legible-computer-font;
    src: url('resources/fonts/Consolas/Consolas-Italic.ttf');
    font-weight: normal;
    font-style: italic;
}
@font-face {
    font-family: legible-computer-font;
    src: url('resources/fonts/Consolas/Consolas-Bold-Italic.ttf');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: game-base-font;
    src: url('resources/fonts/Roboto/Roboto-Regular.ttf');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: game-base-font;
    src: url('resources/fonts/Roboto/Roboto-Bold.ttf');
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-family: game-base-font;
    src: url('resources/fonts/Roboto/Roboto-Italic.ttf');
    font-weight: normal;
    font-style: italic;
}
@font-face {
    font-family: game-base-font;
    src: url('resources/fonts/Roboto/Roboto-BoldItalic.ttf');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: game-accessible-font;
    src: url('resources/fonts/opendyslexic/OpenDyslexic-Regular.woff2');
    font-weight: normal;
    font-style: normal;
    size-adjust: 80%;
}
@font-face {
    font-family: game-accessible-font;
    src: url('resources/fonts/opendyslexic/OpenDyslexic-Bold.woff2');
    font-weight: bold;
    font-style: normal;
    size-adjust: 80%;
}
@font-face {
    font-family: game-accessible-font;
    src: url('resources/fonts/opendyslexic/OpenDyslexic-Italic.woff2');
    font-weight: normal;
    font-style: italic;
    size-adjust: 80%;
}
@font-face {
    font-family: game-accessible-font;
    src: url('resources/fonts/opendyslexic/OpenDyslexic-Bold-Italic.woff2');
    font-weight: bold;
    font-style: italic;
    size-adjust: 80%;
}

@font-face {
    font-family: game-kira-font;
    src: url(resources/fonts/canterbury.regular.ttf) format('truetype');
    size-adjust: 120%;
}

@font-face {
    font-family: game-spiral-font;
    src: url(resources/fonts/oswald/Oswald-Regular.ttf) format('truetype');
    size-adjust: 90%;
}

@font-face {
    font-family: game-title-font;
    font-style: normal;
    font-weight: 400;
    size-adjust: 110%;
    src: url(resources/fonts/Dankduck.ttf) format('truetype');
}


:root{
    --background-color: #0d0d0f;

    --fade-color: #0e101b;

    --primary-color: #272b3b;
    --secondary-color: #232754;
    --text-color: #ffffff;

    --primary-border-color: #545b78;
    --primary-border-shadow-color: #1c1f2d;
    --background-border-color: #33323d;
    --background-border-shadow-color: #0a0a0f;

    --hover-color: #30375a;
    --focus-outline-color: #929ccc;

    font-size: 1em;
    
    --game-font: game-base-font;
    --kira-font: game-kira-font;
    --spiral-font: game-spiral-font;
    --title-font: game-title-font;
}

s {
    text-decoration: line-through;
    text-decoration-thickness: .13rem;
}

*{
    user-select: none;
    
    /* text-shadow: .0625rem .0625rem .13rem rgb(0, 0, 0); */
    text-align: center;
    box-sizing: border-box;

    margin: 0;
    padding: 0;
    
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    color: white;

    font-family: var(--game-font, Arial);
}
.timer-box{
    width: 100%;
    height: .5rem;
    margin: 0%;
    padding: 0%;
    background-color: #220000;
}

button, .button, input, select, option, textarea, .textarea, summary {
    border-radius: 0.4rem;
    margin: .13rem;
    background-color: var(--secondary-color);
    border-color: var(--primary-border-color);
    border-style: solid;
    border-width: .13rem;
    color: white;
    font-size: 1em;
    padding: 0.13rem 0.25rem;
}

/*
Ensure the CSS is EXACTLY the same (FUCK YOU CHROME)
*/
textarea, .textarea {
    display: inline-block;
    unicode-bidi: normal;
    overflow-wrap: normal;
    cursor: text;
    white-space-collapse: preserve;
    text-rendering: optimizeLegibility;
    vertical-align: text-bottom;
    white-space: pre;
    overflow-x: hidden;
    overflow-y: hidden;
}

*:focus-visible {
    outline: var(--focus-outline-color) solid .13rem;
    background-color: var(--hover-color);
}

a {
    text-decoration: none .13rem;
}

a:hover {
    background-color: var(--hover-color);
}

a:focus-visible {
    outline: unset;
    text-decoration-line: underline;
    text-decoration-color: var(--focus-outline-color);
}

button, .button, summary {
    cursor: default;
    background-color: var(--primary-color);
    white-space: nowrap;
}
button, .button, select, summary {
    border-bottom-color: var(--primary-border-shadow-color);
    border-right-color: var(--primary-border-shadow-color);
    user-select: none;
}
input, option, textarea, .textarea {
    border-top-color: var(--primary-border-shadow-color);
    border-left-color: var(--primary-border-shadow-color);
}

:has(input):focus-within > button.clear,
:has(input):focus-within > .button.clear {
    display: block;
}

:has(button.clear), :has(.button.clear) {
    position: relative;
}

button.clear, .button.clear {
    display: none;
    font-size: 0.8em;
    position: absolute;
    right: 0.5rem;
    top: 0.4rem;
    background: none;
    border: none;
    padding: 0;
}

button.close, .button.close {
    font-size: 1em;
    padding: 0;
}

button:disabled, .button:disabled, input:disabled, select:disabled, option:disabled, textarea:disabled, .textarea:disabled, summary:disabled {
    opacity: 0.5;
    box-shadow: 0 0 100vmax #55555555 inset;
}
summary {
    text-align: left;
    padding-left: 0.5rem;
}
.highlighted {
    border-color: yellow;
}
option {
    background-color: var(--background-color);
}

button:hover:not([disabled]):not(:active), .button:hover:not([disabled]):not(:active), input:hover:not([disabled]):not(:active), select:hover:not([disabled]):not(:active), option:hover:not([disabled]):not(:active), summary:hover:not([disabled]):not(:active) {
    background-color: var(--hover-color);
}

.hero {
    height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

code, .code {
    font-family: 'Courier New', Courier, monospace;
    font-weight: bold;
    padding: 0.25rem;
    white-space: pre;
}

::-webkit-scrollbar {
    width: .3rem;
    height: .3rem;
}
/* Track */
::-webkit-scrollbar-track {
    background: #2b2b2b;
    border-radius: .4rem;
}
/* Handle */
::-webkit-scrollbar-thumb {
    background: #616161;
    border-radius: .4rem;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #a1a1a1;
}

::-webkit-scrollbar-corner {
    background: transparent
}

.chat-menu-colors {

    --fade-color: #161b34;

    --primary-color: #1c244e;
    --secondary-color: #232754;
    --text-color: #ffffff;

    --primary-border-color: #3c4675;
    --primary-border-shadow-color: #111836;

    --hover-color: #232c57;
    --focus-outline-color: #929ccc;


    --background-color: #151a41;
    --background-border-color: #2b3162;
    --background-border-shadow-color: #0e1024;

    --primary-color: #1c244e;
    --secondary-color: #232754;
}

.player-list-menu-colors {
    --background-color: #182c28;
    --fade-color: #0b1511;
    --primary-color: #1a3531;
    --secondary-color: #1f3a35;
    --text-color: #ffffff;
    --primary-border-color: #2e5952;
    --primary-border-shadow-color: #142523;
    --background-border-color: #2c4d46;
    --background-border-shadow-color: #121f1c;
    --hover-color: #1f403b;
    --focus-outline-color: #84a997;
}

.will-menu-colors {
    --background-color: #251737;
    --fade-color: #100b15;
    --primary-color: #2c1c40;
    --secondary-color: #332142;
    --text-color: #ffffff;
    --primary-border-color: #4c3558;
    --primary-border-shadow-color: #250d2b;
    --background-border-color: #352d56;
    --background-border-shadow-color: #110d18;
    --hover-color: #38254e;
    --focus-outline-color: #9c84a9;
}

.role-specific-colors {
    --background-color: #331c26;
    --fade-color: #150b12;
    --primary-color: #422031;
    --secondary-color: #422137;
    --text-color: #ffffff;
    --primary-border-color: #5a3246;
    --primary-border-shadow-color: #25141d;
    --background-border-color: #50303e;
    --background-border-shadow-color: #25131c;
    --hover-color: #4c2337;
    --focus-outline-color: #a9849c;
}

.graveyard-menu-colors {
    --background-color: #2c2b3b;
    --fade-color: #1d1b29;
    --primary-color: #323142;
    --secondary-color: #373647;
    --text-color: #ffffff;
    --primary-border-color: #49485b;
    --primary-border-shadow-color: #202027;
    --background-border-color: #3d3c4d;
    --background-border-shadow-color: #19181f;
    --hover-color: #3c3b4d;
    --focus-outline-color: #9896ae;
}

.wiki-menu-colors {
    --background-color: #2C2124;
    --fade-color: #0F0D15;
    --primary-color: #3A2A31;
    --secondary-color: #3A2A31;
    --text-color: #ffffff;
    --primary-border-color: #533C44;
    --primary-border-shadow-color: #261C26;
    --background-border-color: #493942;
    --background-border-shadow-color: #201924;
    --hover-color: #442F35;
    --focus-outline-color: #A18E99;
}


@media only screen and (max-width: 600px) {
    .mobile-hidden {
        display: none;
    }
}

@media (prefers-reduced-motion: no-preference) {
    @keyframes background-scroll {
        from {
            background-position: 0% 0%;
        }
        to {
            background-position: 100% 100%;
        }
    }

    @keyframes glitch-anim {
        0% {
            clip: rect(.89em, 99.99em, .47em, 0);
            transform: skew(0.32deg);
        }
        5% {
            clip: rect(.13em, 99.99em, .94em, 0);
            transform: skew(0.73deg);
        }
        10% {
            clip: rect(.23em, 99.99em, .22em, 0);
            transform: skew(0.1deg);
        }
        15% {
            clip: rect(.84em, 99.99em, .64em, 0);
            transform: skew(0.03deg);
        }
        20% {
            clip: rect(.36em, 99.99em, .33em, 0);
            transform: skew(0.94deg);
        }
        25% {
            clip: rect(.75em, 99.99em, .84em, 0);
            transform: skew(0.27deg);
        }
        30% {
            clip: rect(.66em, 99.99em, .86em, 0);
            transform: skew(0.2deg);
        }
        35% {
            clip: rect(.94em, 99.99em, .23em, 0);
            transform: skew(0.37deg);
        }
        40% {
            clip: rect(.84em, 99.99em, .21em, 0);
            transform: skew(0.5deg);
        }
        45% {
            clip: rect(.68em, 99.99em, 1.00em, 0);
            transform: skew(0.84deg);
        }
        50% {
            clip: rect(.11em, 99.99em, .27em, 0);
            transform: skew(0.95deg);
        }
        55% {
            clip: rect(.79em, 99.99em, .89em, 0);
            transform: skew(0.69deg);
        }
        60% {
            clip: rect(.70em, 99.99em, .14em, 0);
            transform: skew(0.3deg);
        }
        65% {
            clip: rect(.99em, 99.99em, .47em, 0);
            transform: skew(0.95deg);
        }
        70% {
            clip: rect(.66em, 99.99em, .35em, 0);
            transform: skew(0.68deg);
        }
        75% {
            clip: rect(.40em, 99.99em, .55em, 0);
            transform: skew(0.69deg);
        }
        80% {
            clip: rect(.90em, 99.99em, .59em, 0);
            transform: skew(0.07deg);
        }
        85% {
            clip: rect(.17em, 99.99em, .43em, 0);
            transform: skew(0.09deg);
        }
        90% {
            clip: rect(.17em, 99.99em, .52em, 0);
            transform: skew(0.81deg);
        }
        95% {
            clip: rect(.40em, 99.99em, .34em, 0);
            transform: skew(0.44deg);
        }
    }
    @keyframes glitch-anim2 {
        0% {
            clip: rect(.15em, 99.99em, .28em, 0);
            transform: skew(0.11deg);
        }
        5% {
            clip: rect(.02em, 99.99em, .79em, 0);
            transform: skew(0.85deg);
        }
        10% {
            clip: rect(.20em, 99.99em, .53em, 0);
            transform: skew(0.19deg);
        }
        15% {
            clip: rect(.60em, 99.99em, .44em, 0);
            transform: skew(0.64deg);
        }
        20% {
            clip: rect(.61em, 99.99em, .11em, 0);
            transform: skew(0.62deg);
        }
        25% {
            clip: rect(.16em, 99.99em, .73em, 0);
            transform: skew(0.28deg);
        }
        30% {
            clip: rect(.93em, 99.99em, .09em, 0);
            transform: skew(0.57deg);
        }
        35% {
            clip: rect(.86em, 99.99em, .22em, 0);
            transform: skew(0.27deg);
        }
        40% {
            clip: rect(.25em, 99.99em, .02em, 0);
            transform: skew(0.77deg);
        }
        45% {
            clip: rect(.62em, 99.99em, .07em, 0);
            transform: skew(0.83deg);
        }
        50% {
            clip: rect(.10em, 99.99em, .74em, 0);
            transform: skew(0.84deg);
        }
        55% {
            clip: rect(.09em, 99.99em, .17em, 0);
            transform: skew(0.25deg);
        }
        60% {
            clip: rect(.10em, 99.99em, .74em, 0);
            transform: skew(0.48deg);
        }
        65% {
            clip: rect(.48em, 99.99em, .83em, 0);
            transform: skew(0.71deg);
        }
        70% {
            clip: rect(.40em, 99.99em, .09em, 0);
            transform: skew(0.6deg);
        }
        75% {
            clip: rect(.53em, 99.99em, .70em, 0);
            transform: skew(0.15deg);
        }
        80% {
            clip: rect(.97em, 99.99em, .48em, 0);
            transform: skew(0.41deg);
        }
        85% {
            clip: rect(.32em, 99.99em, .82em, 0);
            transform: skew(0.51deg);
        }
        90% {
            clip: rect(.99em, 99.99em, .02em, 0);
            transform: skew(0.38deg);
        }
        95% {
            clip: rect(.12em, 99.99em, .22em, 0);
            transform: skew(0.76deg);
        }
    }
    .glitch {
        display: inline-block;
        text-indent: 0;
        position: relative;
        text-shadow: none;
    }
    .glitch::before {
        content: attr(data-text);
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        left: .02em;
        text-shadow: -.02em 0 var(--background-color);
        clip: rect(.44em, 4.50em, .56em, 0);
        animation: glitch-anim 5s infinite linear alternate-reverse;
    }
    .glitch::after {
        content: attr(data-text);
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        left: -.02em;
        text-shadow: -.02em 0 var(--background-color), .04em 0 var(--background-color);
        clip: rect(.44em, 4.50em, .56em, 0);
        animation: glitch-anim2 5s infinite linear alternate-reverse;
    }
}