@media only screen and (max-width: 600px) {
    .game-screen .content > .graveyard-menu {
        width: 100%;
    }
}
.graveyard-menu .role-list-button{
    text-align: left;
    text-wrap: wrap;
}
.graveyard-menu .graveyard-menu-enabledRoles{
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
    user-select: none;
}
.graveyard-menu .graveyard-menu-enabledRoles > section{
    flex: 0 0 1;
}
.graveyard-menu .graveyard-menu-enabledRoles > div{
    display: block;
}
.graveyard-menu .graveyard-menu-enabledRoles > div > *{
    width: min-content;
}