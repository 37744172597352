.sm {
    min-height: 100%;
    max-height: 100%;
    display: flex;
    flex-direction: column;
}

.sm *:not(.material-icons-round, .keyword-title){
    font-family: var(--computer-font);
}

.sm > header {
    height: 20rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.sm > header > h1 {
    font-size: 6em;
    font-weight: 400;
}

.sm > main {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: start;
}

.sm > main > .satellite {
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.sm > main > .satellite > div {
    flex-basis: 20%;
    flex-grow: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

.sm > main > .satellite > .left {
    align-items: end;
}

.sm > main > .satellite > .center {
    aspect-ratio: 1 / 1;
    min-width: 20rem;
    background: url('../../../public/images/moon.png') center / 80% no-repeat;
}

.sm > main > .satellite > .right {
    align-items: start;
}

.sm > main > .satellite button {
    font-size: 1.5em;
    padding: 0.13em 0.25em;
    min-width: 13em;
}

.sm > footer {
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 0.25rem 1rem;
    padding: 0.25rem;
    background-color: var(--primary-color);
    border-top: var(--primary-border-color) solid .13rem;
}

@media only screen and (max-width: 1000px) {
    .sm > header > h1 {
        font-size: 3em;
    }
    .sm > main > .satellite {
        flex-direction: column;
        align-items: center;
    }
    .sm > main > .satellite > div.left, .sm > main > .satellite > div.right {
        align-items: center;
    }
}

@media only screen and (max-width: 600px) {
    .sm > header {
        height: 15rem;
    }
    .sm > main > .satellite > .center {
        min-width: 15rem;
    }
}