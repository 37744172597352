.credits-menu{
    margin-top: 1rem;
    overflow-y: auto;
    height: 100%;
}
.credits-menu > div{
    position: relative;
    display: flex;
    flex-direction: column;
}
.credits-menu > div > button{
    width: min-content;
    position: sticky;
}
.credits-menu > div > h3 {
    margin-bottom: .5rem;
    margin-top: 2rem;
}
.credits-menu > div > p {
    line-height: 1.5rem;
    display: flex;
    flex-direction: column;
}