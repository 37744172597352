.icon {
    font-size: calc(1.2em * var(--icon-factor));
    user-select: none;
}

button .icon {
    vertical-align: text-top;
}

.icon-small {
    font-size: calc(1em * var(--icon-factor));
    vertical-align: middle;
}

.icon-tiny {
    font-size: calc(0.75em * var(--icon-factor));
    vertical-align:auto;
}