.header-menu .menu-buttons{
    display: flex;
    justify-content: center;
}
.header-menu .menu-buttons button {
    margin: .2%;
    margin-bottom: 0;
    min-width: 10%;
    padding-right: .5rem;
    padding-left: .5rem;
    border-bottom: 0;
    border-radius: .5rem .5rem 0 0;
    background-color: var(--primary-color);
    overflow-x: hidden;
}
.menu-buttons button{
    position: relative;
}
.header-menu .menu-buttons button:hover {
    background-color: var(--hover-color);
}
.header-menu {
    position: relative;
    background-size:auto;
}
.header-menu.background-day{
    background-position: center;
    background-repeat: repeat;
    background-image: url('../../../public/images/day.png');
}
.header-menu.background-night{
    background-position: center;
    background-repeat: repeat;
    background-image: url('../../../public/images/night.png');
}
.header-menu > .information {
    padding: .25rem;
    display: flex;
    flex-direction: column;
    gap: .25rem;
}
.header-menu > .information > .my-information {
    display: flex;
    flex-direction: row;
    justify-content: center;
}.header-menu > .information > .my-information > div {
    width: fit-content;
    background-color: var(--background-color);
    border-color: var(--primary-border-color);
    border-width: .13rem;
    border-style: solid;
    border-radius: .5rem;
    padding: .13rem .25rem;
}

.header-menu .phase-specific {
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.header-menu .phase-specific > div {
    width: fit-content;
    background-color: var(--background-color);
    border-width: .13rem;
    border-style: solid;
    border-radius: .5rem;
    padding: .13rem .25rem;
}
.header-menu .judgement-info > button{
    width: max-content;
    padding: .13rem .13rem;
    margin: .13rem;
} 
.menu-buttons .chat-notification{
    font-size: 0.83rem;
    height: 0.83rem;
    aspect-ratio: 1;
    background-color: var(--primary-color);
    position: absolute;
    padding: 0 0 1rem 0;
    border-width: .13rem;
    border-style: solid;
    border-radius: 50%;
    top: 0;
    right: 0;
}
.header-menu .fast-forward-button{
    position: absolute;
    left: .25rem;
    top: .25rem;
    font-size: 1.5rem;
}