.game-modes-editor {
    display: flex;
    flex-direction: column;
    gap: .5rem;
    width: 100%;
}

.game-modes-editor > header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
    flex-wrap: wrap;
    gap: 0.5rem;
}

.game-modes-editor input {
    min-width: 3rem;
}

.game-modes-editor > main {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1rem;
    overflow-y: scroll;
    scrollbar-width: none;
}

.game-modes-editor > main > div {
    display: flex;
    flex-direction: column;
    flex-basis: 47%;
    flex-grow: 1;
    gap: .5rem;
    max-width: 100%;
}