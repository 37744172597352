.phase-times-selector > .phase-times {
    max-height: 50vh;
    overflow-y: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 0.25rem;
}

.phase-times-selector > .phase-times > div {
    display: flex;
    flex-basis: 11rem;
    flex-grow: 1;
    background-color: var(--secondary-color);
    align-items: center;
    justify-content: space-between;
}
.phase-times-selector > .phase-times > div > span {
    white-space: pre;
}
.phase-times-selector > .phase-times > div > input {
    width: 3rem;
    margin: 0;
    padding: 0;
}