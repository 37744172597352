.play-menu-join-popup {
    max-height: 100%;
    height:fit-content;
    overflow-y: auto;
    display: block;
}

.play-menu-join-popup .rejoinColumn {
    margin-top: 1rem;
}

.rejoinColumn {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}