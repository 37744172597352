.wiki-search {
    width: 100%;
    height: 100%;
    max-height: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: column;
}
.wiki-search-bar {
    display: flex;
    flex-direction: row;
    justify-content: stretch;
}
.wiki-search-bar > input {
    width: 100%;
}
.wiki-search-bar > button {
    width: fit-content;
}
.wiki-results {
    margin: .75%;
    overflow-y: auto;
    flex: 1 1;
    padding-bottom: 1rem;
}
.wiki-search .masonry-item {
    width: 100%;
}
.wiki-message-section {
    display: flex;
    flex-direction: column;
    margin-top: .1rem;
    margin-bottom: .6rem;
}
.wiki-article {
    text-align: left;
    height: 100%;
    width: 100%;
    overflow-y: auto;
    padding-bottom: 1rem;
}
/* Markdown */
.wiki-article > div {
    margin: .25rem;
    margin-bottom: 1rem;
    margin-top: 1rem;
}
.wiki-article * {
    text-align: left;
}
.wiki-article .details-summary-container {
    padding: 0.12rem;
    margin: .25rem 0;
    border: .13rem solid var(--primary-border-color);
    border-radius: 0.5rem;
    text-align: center;
}
.wiki-article blockquote {
    width: 100%;
    background-color: var(--primary-color);
    padding-top: 0.1rem;
    padding-bottom: 0.01rem;
    margin-bottom: 0.5rem;
    text-align: left;
}
.wiki-article blockquote > * {
    display: inline-block;
    margin: auto;
    text-align: left;
}
.wiki-article h1 {
    text-align: center;
    font-size: 150%;
    margin-bottom: 0.25rem;
}
.wiki-article h2 {
    text-align: center;
    font-size: 125%;
    margin-bottom: 0.25rem;
}
.wiki-article h3 {
    text-align: center;
    margin-bottom: 0.5rem;
}
.wiki-article p {
    margin-bottom: 0.5rem;
}
.wiki-article ul, .wiki-article ol {
    padding-left: 1.5rem;
    margin-bottom: 0.5rem;
}
.wiki-search-divider {
    margin: 0.5rem;
    padding: 0.3rem;
    border-radius: 1rem;
    background-color: var(--secondary-color);
}
.wiki-article-standard {
    text-align: left;
}
.wiki-cover-card, .anchor-cover-card .wiki-article {
    width: 100%;
}
.wiki-cover-card {
    width: 80rem;
    min-height: 60vh;
}
.role-set-article .masonry-item {
    text-align: center;
}