.pretty-text-area {
    display: flex;
    flex-direction: column;
    align-items: stretch;
}
.text-area-dropdown .textarea, .text-area-dropdown textarea {
    text-align: left;
    /* height: 20rem; */
    height: fit-content;
    min-height: 2rem;
    margin: .13rem;
    text-wrap: wrap;
    resize: none;
}
.text-area-dropdown .textarea > * {
    text-wrap: pretty;
}
.text-area-dropdown .details-summary-summary-container > div {
    display: flex;
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    padding-left: .25rem;
    justify-content: space-between;
    text-align: left;
    align-items: baseline;
}
.text-area-dropdown .details-summary-summary-container > div > div {
    display:flex;
    flex-wrap: wrap;
    gap: 0.25rem;
    align-items: center;
}