@media only screen and (max-width: 600px) {
    .game-screen .content > .player-list-menu {
        width: 100%;
    }
}


.player-list-menu {
    display: flex;
    flex-direction: column;
}
.player-list-menu .player-list {
    display: flex;
    flex-direction: column;
}



.player-list-menu .dead-players-separator {
    border-top: .13rem solid var(--primary-border-color);
    border-bottom: .13rem solid var(--primary-border-shadow-color);
    border-left: .13rem solid transparent;
    border-right: .13rem solid transparent;
    background-color: var(--secondary-color);
    margin: 2rem 0 0;
}

.player-list-menu .player.highlighted {
    outline: .13rem solid yellow;
}
.player-list-menu .player .voted-popup {
    position: relative;
    top: 0;
}
.player-list-menu .playerTags{
    font-size: 1.125em;
}
.player-list-menu .phase-specific{
    background-color: var(--primary-color);
    border-top: .13rem solid var(--primary-border-color);
    padding: .13rem .25rem;
}


.player-card {
    display: flex;
    flex-direction: row;
    align-items: center;

    padding: 0 .13rem;
    position: relative;
}
.player-card > button{
    position: relative;
}
.player-card > .player-name-plate {
    flex-grow: 1;
}

.player-list-menu .player-list-chat-section{
    border-top: var(--primary-border-color) solid .13rem;
    border-bottom: var(--primary-border-color) solid .13rem;
}
.player-list-menu .player-list-chat-message-section{
    height: 10rem;
}

.player-list-menu .player-card-holder:nth-last-child(even) {
    background-color: var(--primary-color);
}
.player-list-menu .player-card-holder:nth-last-child(odd) {
    background-color: var(--secondary-color);
}

.player-card .chat-notification{
    font-size: 0.83rem;
    height: 0.83rem;
    aspect-ratio: 1;
    background-color: var(--primary-color);
    position: absolute;
    padding: 0;
    border-width: .13rem;
    border-style: solid;
    border-radius: 50%;
    top: 0;
    right: 0;
}