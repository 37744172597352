
.lm {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    height: 100%;
    align-items: center;
}

.lm > div {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    gap: 1rem;
    width: 100rem;
    max-width: 100%;
    height: 100%;
}

.lm > div > header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 0.5rem;
    font-size: 1.25em;
}

.lm > div > header div{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 0.5rem;
}

.lm > div > header > * {
    max-width: 100%;
}

@media only screen and (max-width: 600px) {
    .lm > div > header {
        justify-content: center;
    }
}

.lm > div > main ol {
    list-style: none;
}

.lm > div > main {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex-grow: 1;
    row-gap: 1rem;
    column-gap: 1rem;
}

.lm > div > main > div {
    display: flex;
    flex-direction: column;
    flex-basis: 47%;
    max-width: 100%;
    flex-grow: 1;
    gap: 1rem;
}

.lm > div .name-box {
    display: flex;
    flex-direction: row;
}

.lm > div .name-box input {
    width: 100%;
}

.lobby-name-pane {
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.lobby-name-pane > .name-pane-selector {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.lobby-name-pane > .name-pane-buttons {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-end;
}

.lobby-name {
    display: flex;
    width: 100%;
    justify-content: space-between;
}
.lobby-name button{
    flex-shrink: 1;
}
.lobby-name section{
    flex-grow: 1;
}

.lm > div .player-list > .list {
    border: .13rem solid var(--background-border-color);
    border-top-color: var(--background-border-shadow-color);
    border-left-color: var(--background-border-shadow-color);
    border-radius: .5rem;
}

.lm > div .player-list > .spectators-ready {
    text-align: left;
    margin-top: .5rem;
}

.lm > div .player-list ol li {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: .13rem .25rem;
    gap: .5rem;
    align-items: center;
    min-height: 2em;
}

.lm > div .player-list ol li:nth-of-type(even) {
    background-color: var(--primary-color);
}

.lm > div .player-list ol li > div {
    display: flex;
    flex-direction: row;
    gap: .25rem;
}
